<template>
  <HomeCliente v-if="isCliente"/>
  <Home v-else></Home>
  <v-dialog
      v-model="dialogInicial"
      fullscreen
      class="h-100 bg-secondary"
      transition="dialog-bottom-transition"
  >
    <v-container
        :class="isMobile ? '' : 'h-100'"
        class="pa-0"
        fluid>
      <LazyHeaderSempre/>
<!--      <NuxtImg-->
<!--          preload-->
<!--          :src="isMobile ? '/BANNER-BEM-VINDO-&#45;&#45;MOBILE.png' : '/BANNER INICIAL.png'"-->
<!--          alt="Seja bem-vindo! Viva em um mundo Sempre conectado com a melhor experiência de internet."-->
<!--          class="banner w-100"-->
<!--          placeholder/>-->
      <NuxtImg
          preload
          :src="isMobile ? '/banner_mobile_h_600x200.png' : '/SEMPRE_BANNER_SITE_1600x300.png'"
          alt="Sempre Internet, mais um ano celebrando nossa conexão com você!"
          class="w-100 h-auto"
          placeholder/>
      <v-container
          class="text-center">
        <div
            class="h-100 d-flex flex-column justify-center">
          <div
              style="font-size: 24px"
              class="mb-5 font-weight-bold">
            <p>BEM-VINDO À SUA MELHOR EXPERIÊNCIA EM CONEXÃO</p>
          </div>
          <div class="mb-5 font-weight-regular">
            <p>ESCOLHA UMA OPÇÃO.</p>
          </div>
          <v-container>
            <v-row>
              <v-col
                  :class="isMobile ? 'justify-center' : 'justify-end'"
                  class="d-flex"
                  cols="12"
                  sm="6">
                <v-btn
                    class="botoes-iniciais borda-branca"
                    color="secondary"
                    height="50"
                    width="300"
                    @click="selecionarSouCliente">
                  Sou cliente
                </v-btn>
              </v-col>
              <v-col
                  :class="isMobile ? 'justify-center' : 'justify-start'"
                  class="d-flex"
                  cols="12"
                  sm="6">
                <v-btn
                    class="botoes-iniciais borda-branca"
                    color="secondary"
                    height="50"
                    width="300"
                    @click="selecionarQueroSerCliente">
                  Quero ser cliente
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </div>
      </v-container>
    </v-container>
  </v-dialog>
</template>
<script lang="ts">
import HomeCliente from "~/pages/home-cliente.vue";
import Home from "~/pages/home.vue";

export default defineComponent({
  components: {Home, HomeCliente},
  data() {
    return {
      dialogInicial: true,
      isCliente: false,
    }
  },
  methods: {
    fecharDialogInicial() {
      this.dialogInicial = false;
    },

    selecionarSouCliente() {
      this.isCliente = true;
      this.fecharDialogInicial();
    },

    selecionarQueroSerCliente() {
      this.isCliente = false;
      this.fecharDialogInicial();
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.xs
    }
  }
})
</script>
<style scoped>
.borda-branca {
  border: 2px solid white !important;
}

.botoes-iniciais {
  border-radius: 20px !important;
}

.banner {
  height: 357px !important;
}

@media (max-width: 1600px) {
  .banner {
    height: 300px !important;
  }
}

@media (max-width: 1264px) {
  .banner {
    height: 250px !important;
  }
}

@media (max-width: 960px) {
  .banner {
    height: 225px !important;
  }
}

@media (max-width: 600px) {
  .banner {
    height: 140px !important;
  }
}

:deep(.v-overlay__scrim) {
  background: none;
}
</style>
